@use "./config/" as *;
@forward './main.scss';
@forward './listing/multimedia-common-listing';
@forward './module/triathlon-live';
.waf-listing {
    .waf-head {
        .title {
            &::before {
                content: unset;
            }
        }
    }
    .tab-container {
        margin-top: 0;
        border-radius: 0 0 var(--border-radius-xl) var(--border-radius-xl);
    }
    .priamry-tabs {
        border-bottom: 0;
        padding-inline: var(--space-4);
    }
}
@include mq(col-tablet) {
    .waf-listing {
        .priamry-tabs {
            padding-top: var(--space-14);
            padding-inline: var(--space-10);
        }
        .tab-container {
            padding: var(--space-10);
        }
    }
    .waf-broadcast {
        &.waf-multimedia-broadcast {
            padding-top: 0;
            .streaming {
                padding-inline: var(--space-6);
            }
        }
    }
}